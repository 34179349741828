<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :class="drawer ? 'drawer-close' : 'drawer-open'"
    :mini-variant.sync="drawer"
    mini-variant-width="60"
    permanent
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    v-bind="$attrs"
  >
    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item class="brand-logo">
        <v-list-item-avatar
          class="align-self-center company-logo"
          color="dark"
          tile
          contain
        >
          <v-img
            v-if="user.isAdmin"
            src="@/assets/brandLogo/logo.png"
          />
          <v-img
            v-else
            :src="user.business_logo"
          />
        </v-list-item-avatar>
        <div class="content">
          <div class="title">
            {{ profile.title }}
          </div>
          <div
            v-if="profile.subTitle"
            class="subTitle"
          >
            {{ profile.subTitle }}
          </div>
        </div>

        <!-- <v-list-item-content>
          <v-list-item-title class="display-1">
            {{ profile.title }}
          </v-list-item-title>
        </v-list-item-content> -->
      </v-list-item>
    </v-list>

    <v-divider class="mb-2 mx-3" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
      <template v-for="(item, i) in computedItems">
        <v-list-group
          v-if="item.children.length > 0"
          :key="i"
          :value="i == 0 ? true : false"
        >
          <!-- :prepend-icon="item.icon" -->
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon v-if="item.icon">
                {{ item.icon }}
              </v-icon>
              <Icons
                v-else
                :name="item.myIcon"
              />
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>

          <v-list-item
            v-for="(row, j) in item.children"
            :key="j"
            :to="row.to"
            :class="checkActivePath(row.name)"
            @click="changeRoute(row, row.name)"
          >
            <v-list-item-icon>
              <v-icon v-if="row.icon">
                {{ row.icon }}
              </v-icon>
              <Icons
                v-else
                :name="row.myIcon"
                size="sm"
              />
            </v-list-item-icon>
            <v-list-item-title>{{ row.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-else-if="item.to"
          :key="'u' + i"
          :to="item.to"
          :class="checkActivePath(item.name)"
          @click="changeRoute(item, item.name)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>

        <!-- <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        /> -->
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <v-divider class="mb-2 mx-3" />

    <!-- <template v-slot:append>
      <v-list
        expand
        nav
      >
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon v-text="`mdi-power-standby`" />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="$t('logout')" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template> -->
  </v-navigation-drawer>
</template>
<style scoped>
.v-list--nav {
  padding-left: 5px;
  padding-right: 5px;
}

.v-navigation-drawer--mini-variant .v-list-item__avatar:first-child {
  margin-right: 0px !important;
}
</style>

<script>
/* eslint-disable */
// Utilities
import { mapState } from "vuex";

export default {
  name: "DashboardCoreDrawer",
  components: { Icons: () => import("../../components/base/icons.vue") },
  props: {
    expandOnHover: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mini: true,
      items: [
        // Dashboard
        // {
        //   icon: "mdi-view-dashboard",
        //   title: "dashboard",
        //   name: "dashboard",
        //   to: "/",
        //   children: [],
        // },

        //
        ...(this.$admin.hasAccessTo("dashboard.list")
          ? [
              {
                icon: "mdi-view-dashboard",
                title: "dashboard",
                name: "dashboard",
                to: "/",
                children: [],
              },
            ]
          : []),
        // Dashboard

        // Vehicle Telematic
        {
          myIcon: "electric-bike",
          title: "telematics",
          name: "telematics",
          group: "telematics",
          expanded: true,
          children: [
            ...(this.$admin.hasAccessTo("md-makes.list")
              ? [
                  {
                    // icon: "mdi-table-cog",
                    myIcon: "ev_make",
                    title: "md_makes",
                    name: "md_makes",
                    to: "/md-makes",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("md-models.list")
              ? [
                  {
                    // icon: "mdi-table-cog",
                    myIcon: "ev_model",
                    title: "md_models",
                    name: "md_models",
                    to: "/md-models",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("vehicles.list")
              ? [
                  {
                    icon: "mdi-atv",
                    title: "vehicles",
                    name: "vehicles",
                    to: "/vehicles",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("vehicle-telematics.list")
              ? [
                  {
                    myIcon: "telematics",
                    title: "vehicle_telematics",
                    name: "vehicle_telematics",
                    to: "/vehicle-telematics",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("vehicle-trips.list")
              ? [
                  {
                    icon: "mdi-road-variant",
                    title: "trips",
                    name: "trips",
                    to: "/trips",
                  },
                ]
              : []),

            ...(this.$admin.hasAccessTo("vehicle-charge-sessions.list")
              ? [
                  {
                    icon: "mdi-ev-station",
                    title: "charge_session",
                    name: "charge_session",
                    to: "/charge-session",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("battery-diagnostics.list")
              ? [
                  {
                    icon: "mdi-battery-sync",
                    title: "battery_diagnostics",
                    name: "battery_diagnostics",
                    to: "/battery-diagnostics",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("vehicles.remote")
              ? [
                  {
                    icon: "mdi-remote",
                    title: "remote_on_off",
                    name: "remote_on_off",
                    to: "/vehicle-activity",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("staff-assignment.list")
              ? [
                  {
                    myIcon: "customer",
                    title: "staff_assignment",
                    name: "staff_assignment",
                    to: "/staff_assignment",
                  },
                ]
              : []),
          ],
        },
        // Vehicle Telematic

        // Maintenance
        {
          icon: "mdi-cogs",
          title: "maintenance",
          name: "maintenance",
          group: "maintenance",
          expanded: false,
          children: [
            ...(this.$admin.hasAccessTo("vehicle-maintenance-logs.list")
              ? [
                  {
                    icon: "mdi-wrench-cog",
                    title: "vehicle_maintenance_logs",
                    name: "vehicle_maintenance_logs",
                    to: "/vehicle-maintenance-logs",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("upcoming-vehicle-maintenance.list")
              ? [
                  {
                    icon: "mdi-cog-transfer",
                    title: "upcoming_vehicle_maintenance",
                    name: "upcoming_vehicle_maintenance",
                    to: "/upcoming-vehicle-maintenance",
                  },
                ]
              : []),
          ],
        },
        // Maintenance

        // Performance Management
        {
          icon: "mdi-speedometer",
          title: "performance",
          name: "performance",
          group: "performance",
          expanded: false,
          children: [
            ...(this.$admin.hasAccessTo("vehicle-comparision.list")
              ? [
                  {
                    // icon: "mdi-call-split",
                    myIcon: "bike-comparison1",
                    title: "vehicle_comparison",
                    name: "vehicle_comparison",
                    to: "/vehicle-comparison",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("driver-comparision.list")
              ? [
                  {
                    // icon: "mdi-call-split",
                    myIcon: "driver-comparison",
                    title: "driver_comparison",
                    name: "driver_comparison",
                    to: "/driver-comparison",
                  },
                ]
              : []),
          ],
        },
        // Performance Managment

        // Route Optimizied
        {
          icon: "mdi-google-maps",
          title: "routes",
          name: "routes",
          group: "routes",
          expanded: true,
          children: [
            ...(this.$admin.hasAccessTo("route-tracking.list")
              ? [
                  {
                    icon: "mdi-map-marker-radius",
                    title: "route-optimization",
                    name: "route-optimization",
                    to: "/route-optimization",
                    children: [],
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("routes.list")
              ? [
                  {
                    icon: "mdi-map",
                    title: "route_listing",
                    name: "route_listing",
                    to: "/route-listing",
                    children: [],
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("route-planning.list")
              ? [
                  {
                    icon: "mdi-map-marker-distance",
                    title: "route_planning",
                    name: "route_planning",
                    to: "/route-planning",
                    children: [],
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("routes.comparision")
              ? [
                  {
                    icon: "mdi-map-marker-multiple",
                    title: "route_comparison",
                    name: "route_comparison",
                    to: "/route-comparison",
                    children: [],
                  },
                ]
              : []),
          ],
        },
        // Route Optimizied

        // Charging Management
        {
          icon: "mdi-lightning-bolt",
          title: "charging-management",
          name: "charging-management",
          group: "charging-management",
          expanded: false,
          children: [
            ...(this.$admin.hasAccessTo("locations.list")
              ? [
                  {
                    icon: "mdi-map-marker",
                    title: "locations",
                    name: "locations",
                    to: "/locations",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("rates.list")
              ? [
                  {
                    icon: "mdi-finance",
                    title: "rates",
                    name: "rates",
                    to: "/rates",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("charge-stations.list")
              ? [
                  {
                    icon: "mdi-ev-station",
                    title: "charge_stations",
                    name: "charge_stations",
                    to: "/charge_stations",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("bookings.list")
              ? [
                  {
                    icon: "mdi-book-check",
                    title: "bookings",
                    name: "bookings",
                    to: "/bookings",
                  },
                ]
              : []),
          ],
        },
        // Charging Management

        // Reports
        {
          icon: "mdi-file-chart",
          title: "reports",
          name: "reports",
          group: "reports",
          expanded: false,
          children: [
            ...(this.$admin.hasAccessTo("journey-reports.list")
              ? [
                  {
                    icon: "mdi-poll",
                    title: "journey_reports",
                    name: "journey_reports",
                    to: "/journey-reports",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("daily-mileage-reports.list")
              ? [
                  {
                    icon: "mdi-flag-triangle",
                    title: "daily_mileage_reports",
                    name: "daily_mileage_reports",
                    to: "/daily-mileage-reports",
                  },
                ]
              : []),
          ],
        },
        // Reports
        // Flespi Management
        {
          myIcon: "flespi",
          title: "flespi",
          name: "flespi",
          group: "flespi",
          expanded: false,
          children: [
            ...(this.$admin.hasAccessTo("flespi-streams.list")
              ? [
                  {
                    icon: "mdi-call-split",
                    title: "streams",
                    name: "streams",
                    to: "/streams",
                  },
                ]
              : []),

            ...(this.$admin.hasAccessTo("flespi-plugins.list")
              ? [
                  {
                    icon: "mdi-power-plug",
                    title: "plugins",
                    name: "plugins",
                    to: "/plugins",
                  },
                ]
              : []),

            ...(this.$admin.hasAccessTo("flespi-calcs.list")
              ? [
                  {
                    icon: "mdi-calculator",
                    title: "calculators",
                    name: "calculators",
                    to: "/calculators",
                  },
                ]
              : []),
          ],
        },
        // Flespi Management

        // Setup
        {
          myIcon: "setting",
          title: "setup",
          name: "setup",
          group: "setup",
          expanded: false,
          children: [
            ...(this.$admin.hasAccessTo("role")
              ? [
                  {
                    icon: "mdi-account-lock",
                    title: "roles",
                    name: "roles",
                    to: "/roles",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("user")
              ? [
                  {
                    icon: "mdi-account",
                    title: "users",
                    name: "users",
                    to: "/users",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("tenants.list")
              ? [
                  {
                    icon: "mdi-table-cog",
                    title: "tenants_management",
                    name: "tenants_management",
                    to: "/tenants-management",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("tenant-configurations.list")
              ? [
                  {
                    icon: "mdi-table-cog",
                    title: "tenant_configurations",
                    name: "tenant_configurations",
                    to: "/tenant-configurations",
                  },
                ]
              : []),
            // ...(this.$admin.hasAccessTo("oem-clients.list")
            //   ? [
            //       {
            //         icon: "mdi-table-cog",
            //         title: "oem-clients.index",
            //         name: "oem-clients.index",
            //         to: "/oem-clients/index",
            //       },
            //     ]
            //   : []),
            ...(this.$admin.hasAccessTo("data-sims.list")
              ? [
                  {
                    icon: "mdi-sim",
                    title: "data_sims",
                    name: "data_sims",
                    to: "/data-sims",
                  },
                ]
              : []),

            ...(this.$admin.hasAccessTo("iot-device-models.list")
              ? [
                  {
                    icon: "mdi-devices",
                    title: "iot_device_providers",
                    name: "iot_device_providers",
                    to: "/iot-device-models",
                  },
                ]
              : []),

            ...(this.$admin.hasAccessTo("iot-devices.list")
              ? [
                  {
                    icon: "mdi-tablet-cellphone",
                    title: "iot_devices",
                    name: "iot_devices",
                    to: "/iot-devices",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("driving-staff.list")
              ? [
                  {
                    myIcon: "customer",
                    title: "driving_staff",
                    name: "driving_staff",
                    to: "/driving-staff",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("oem-customers.list")
              ? [
                  {
                    myIcon: "customer",
                    title: "oem_customers",
                    name: "oem_customers",
                    to: "/oem-customers",
                  },
                ]
              : []),
            ...(this.$admin.hasAccessTo("oem-b2b.list")
              ? [
                  {
                    icon: "mdi-office-building",
                    title: "oem_business",
                    name: "oem_business",
                    to: "/oem-business",
                  },
                ]
              : []),

            ...(this.$admin.hasAccessTo("batteries.list")
              ? [
                  {
                    icon: "mdi-battery-charging",
                    title: "batterries",
                    name: "batterries",
                    to: "/batteries",
                  },
                ]
              : []),
          ],
        },
        // Setup

        // Help and Support
        ...(this.$admin.hasAccessTo("help-support.list")
          ? [
              {
                icon: "mdi-face-agent",
                title: "support_tickets",
                name: "support_tickets",
                to: "/support-tickets",
                children: [],
              },
            ]
          : []),
        // {
        //   icon: "mdi-view-dashboard",
        //   title: "dashboard",
        //   name: "dashboard",
        //   to: "/",
        // },

        // ...(this.$admin.hasAny(["oem-clients.list"])
        //   ? [
        //       {
        //         icon: "mdi-table-cog",
        //         title: "oem-management",
        //         name: "oem-clients",
        //         group: "oem-clients",
        //         expanded: true,
        //         children: [

        //         ],
        //       },
        //     ]
        //   : []),

        // ...(this.$admin.hasAccessTo("help-support.list")
        //   ? [
        //       {
        //         icon: "mdi-ticket-account",
        //         title: "support-tickets",
        //         name: "csupport-tickets",
        //         to: "/support-tickets",
        //       },
        //     ]
        //   : []),
      ],
    };
  },

  computed: {
    ...mapState(["barColor", "barImage"]),
    activePath() {
      return this.$route.name;
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
        subTitle: this.$t("sub-avatar"),
      };
    },

    user() {
      return this.$store.getters.getUser;
    },
  },

  methods: {
    changeRoute(path, id) {
      let params = {};
      this.$store.dispatch("redirect/getQueryParams", id).then((res) => {
        params = { ...res };
        if (params.date) {
          params.dateRange = params.date;
          delete params.date;
        }
        const PATH = {
          name: id,
          query: params,
        };
        // this.$router.push(path.to).catch(() => {});
        this.$router.push(PATH).catch(() => {});
      });
    },
    checkActivePath(routeName) {
      if (this.activePath == routeName) {
        return "primary";
      } else if (
        this.activePath == "telematic_details" &&
        routeName == "vehicle_telematics"
      ) {
        return "primary";
      }
      // else if (
      //   this.activePath == "battery_diagnostics" &&
      //   routeName == "vehicle_telematics"
      // ) {
      //   return "primary";
      // }
      else if (this.activePath == "trip-listing" && routeName == "trips") {
        return "primary";
      } else if (this.activePath == "trip-detail" && routeName == "trips") {
        return "primary";
      } else if (
        this.activePath == "charge-session-list" &&
        routeName == "charge_session"
      ) {
        return "primary";
      } else if (
        this.activePath == "battery_diagnostics_list" &&
        routeName == "battery_diagnostics"
      ) {
        return "primary";
      } else if (
        this.activePath == "route-create" &&
        routeName == "route_listing"
      ) {
        return "primary";
      } else if (
        this.activePath == "route-edit" &&
        routeName == "route_listing"
      ) {
        return "primary";
      } else if (
        this.activePath == "job_route" &&
        routeName == "route_planning"
      ) {
        return "primary";
      } else {
        return "";
      }
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },

    // logout() {
    //   this.$store.dispatch("logout");
    //   this.$store.dispatch("checkAuth");
    // },
  },
};
</script>
<style lang="sass" scoped>

$activeColor : #23BDAA

.brand-logo
  color: white !important
  justify-content: center
  align-items: center
  .title
    font-size: 22px !important
    font-weight: 600
  .subTitle
    font-size: 12px
.drawer-open .v-list .v-list-group .v-list-item
  padding-left: 2rem
  color:  white !important
  i
    color: white !important

.v-list-item,.v-list-group
  .v-list-item__title
    color: white !important
  :deep .v-list-item__icon
    i
      color: white !important

  .v-list-group__header__append-icon
    background: red !important
// .v-list-group--active
//   color : white !important

.primary
  background: #0d4c45 !important
  .v-list-item__icon, .v-list-item__title
    color: $activeColor  !important
    i
      color: $activeColor  !important
    span
      :deep svg
        g
          fill: $activeColor !important
</style>
